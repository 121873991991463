import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import {
    Body,
    ContentContainer,
} from 're-cy-cle';
import { Sidebar } from 'spider/semantic-ui/Admin/Overview';
import { Form, Header } from 'semantic-ui-react';
import { TargetTextInput } from 'spider/semantic-ui/Target';
import Toolbar from 'spider/component/Toolbar';
import RightDivider from 'spider/component/RightDivider';
import { SaveButton } from 'spider/Button';

@observer
export default class NamespaceEdit extends Component {
    static propTypes = {
        namespace: PropTypes.object.isRequired,
        userInViewStore: PropTypes.object.isRequired,
        save: PropTypes.func.isRequired,
    }

    render() {
        const { namespace, save } = this.props;
        return (
            <Body>
                <ContentContainer>
                    <Sidebar>
                        <Header as="h1">
                            {namespace.id
                                ? t('namespace.edit.title')
                                : t('namespace.create.title') }
                        </Header>
                        <Form>
                            <TargetTextInput target={namespace} name="name" />
                        </Form>
                    </Sidebar>
                </ContentContainer>
                <Toolbar>
                    <RightDivider />
                    <SaveButton primary compact loading={namespace.isLoading} onclick={save} />
                </Toolbar>
            </Body>
        )
    }
}
